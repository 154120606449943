import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Text = makeShortcode("Text");
const MembersSection = makeShortcode("MembersSection");
const Grid = makeShortcode("Grid");
const Pattern = makeShortcode("Pattern");
const Card = makeShortcode("Card");
const JobsSection = makeShortcode("JobsSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section on="page-about" mdxType="Section">
  <Text as="p" mod="my-6 md:my-8 xl:my-10" pattern="subtitle" mdxType="Text">
    HH Consulting is a team of structural engineers serving homeowners,
    contractors, and owner-builders in all 50 states. We're based in Henderson,
    Nevada.
  </Text>
    </Section>
    <MembersSection on="page-about" mdxType="MembersSection" />
    <Section on="page-about" mod="bg-gray-50" pattern="feature" mdxType="Section">
  <Grid cols="md:grid-cols-2 gap-6 md:gap-8 xl:gap-10" mdxType="Grid">
    <Pattern of="col" mdxType="Pattern">
      <Text as="h4" mod="mb-4 md:mb-5 xl:mb-6" pattern="title" mdxType="Text">
        Our Mission
      </Text>
      <Text mdxType="Text">
        We’re on a relentless pursuit to improve for our clients, the industry,
        and ourselves through continuous education, building better
        relationships, and actively participating in the exchange of knowledge.
      </Text>
    </Pattern>
    <Pattern of="col" mdxType="Pattern">
      <Text as="h4" mod="mb-4 md:mb-5 xl:mb-6" pattern="title" mdxType="Text">
        Our Vision
      </Text>
      <Text mdxType="Text">
        By building stronger relationships daily—one phone call, site visit, or
        meeting at a time—we’re creating a more connected web of professionals.
        Our vision is a stronger network that supports our customers and
        enhances the industry we love.
      </Text>
    </Pattern>
  </Grid>
    </Section>
    <Section on="page-about" pattern="feature" mdxType="Section">
  <Text as="h4" mod="mb-12 md:mb-14 xl:mb-16 text-center" pattern="title" mdxType="Text">
    Our Core Values
  </Text>
  <Grid cols="md:grid-cols-2 gap-8 md:gap-10 xl:gap-12" mdxType="Grid">
    <Card of="col" mdxType="Card">
      <Text as="h4" mod="mb-2 md:mb-3 xl:mb-4" pattern="subtitle" mdxType="Text">
        Evolutionary
      </Text>
      <Text mdxType="Text">
        Innovation is a given—it’s in our DNA. With innovation leading the
        charge, we’ve set our sights on evolving the industry for the greater
        good.
      </Text>
    </Card>
    <Card of="col" mdxType="Card">
      <Text as="h4" mod="mb-2 md:mb-3 xl:mb-4" pattern="subtitle" mdxType="Text">
        United
      </Text>
      <Text mdxType="Text">
        Driven by the common goal of strengthening the network of professionals
        we’re a part of, the HH Consulting team is united like family, and we
        treat each other accordingly.
      </Text>
    </Card>
    <Card of="col" mdxType="Card">
      <Text as="h4" mod="mb-2 md:mb-3 xl:mb-4" pattern="subtitle" mdxType="Text">
        Unexpected
      </Text>
      <Text mdxType="Text">
        We never settle for the status quo. From how we treat clients to how we
        treat each other, we pave our way every single day.
      </Text>
    </Card>
    <Card of="col" mdxType="Card">
      <Text as="h4" mod="mb-2 md:mb-3 xl:mb-4" pattern="subtitle" mdxType="Text">
        Passionate
      </Text>
      <Text mdxType="Text">
        Passion is a prerequisite here. Because if you don’t love what we do,
        what’s the point?
      </Text>
    </Card>
  </Grid>
    </Section>
    <JobsSection id="jobs" on="page-about" mdxType="JobsSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      